<template>
    <div class="overlay w-all p-2 lg:p-4">
        <slot />
    </div>
</template>
<style>
.overlay {
    background-color: #E5E5E5;
}
</style>
